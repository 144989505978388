import React,{useState,useEffect} from 'react';
import nube from './assets/images/Weather-cloud-rain_icon-icons.com_51829.png'
import './App.css';

// const urls  = {
//                 Pronostico:"http://siata.gov.co:8089/pronosticoMunicipiosSimplificado/cc77055bdd78258a6a92daa6e9de0fa0732a0a36/?format=json",
//                 Temperatura:"http://siata.gov.co:8089/estacionesMeteo/cc77055bdd78258a6a92daa6e9de0fa0732a0a36/",
//                 EstacionesAire :"http://siata.gov.co:8089/estacionesAirePM25/cc77055bdd78258a6a92daa6e9de0fa0732a0a36/",
//                 Test:"http://api.laf.com.co/",
//                 Test2:"http://siata.gov.co:8089/estacionesAirePM25/cf7bb09b4d7d859a2840e22c3f3a9a8039917cc3/?format=json",
//               }


const App=()=>{

  const [pronostico, setPronostico] = useState(false);
  const [estacionesAirePM25, setEstacionesAirePM25] = useState(false);
  const [estacionesMeteo, setEstacionesMeteo] = useState(false);

  const PostAsync =  async (modulo,func,datos) =>  {
    let headers   =   new Headers();
    let data      =   new FormData();
        data.append ("datos",datos);
    let cabecera  =   {
                        headers:headers,
                        method: "POST",
                        body: data
                      }
    try {
      const response    =   await fetch(modulo,cabecera);
      const json        =   await response.json();
      func(json.datos)
      return json;
    }catch (error) {
      return error;
    }
  }



  useEffect(() => {
    PostAsync("http://localhost/api.laf.com.co/Meteorologia", setPronostico,"pronosticoMunicipiosSimplificado")
    PostAsync("http://localhost/api.laf.com.co/Meteorologia", setEstacionesAirePM25,"estacionesAirePM25")
    PostAsync("http://localhost/api.laf.com.co/Meteorologia", setEstacionesMeteo,"estacionesMeteo")
    document.getElementById("row").style.height = window.innerHeight+"px";
    document.getElementById("row2").style.height = (window.innerHeight-100)+"px";
  },[])


  return  <div className="container-fluid">
            <div className="row" id="row">
              <div className="col">
                <div className="pt-5 pb-5 pr-0 pl-0">
                  <div className="card-2 bg-gray">
                    <div className="row" id="row2">
                      <div className="col-5 position-relative">
                        <div className="h3 text-warning text-center pt-5">
                          Pronóstico <br/>meteorológico
                        </div>
                        <div className="text-center">
                          <img src={nube} className="col-3 d-none" alt=""/>
                        </div>
                        <div className="h3 text-white text-center pt-5">
                          {estacionesAirePM25 && estacionesAirePM25[5]!==undefined?<> {estacionesAirePM25[5].recomendaciones[0].descripcion}</>:''}
                        </div>
                        <div className="separador"></div>
                      </div>
                      <div className="col-3">
                        <div className="h3 text-warning text-center pt-5">
                          Temperatura
                        </div>
                        <div className="bigText  text-white text-center">
                          {pronostico && pronostico[6]!==undefined? pronostico[6].medicion:''}
                        </div>
                        <div className="separador"></div>
                      </div>
                      <div className="col-4">
                        <div className="h3 text-warning text-center pt-5">
                          Calidad del aire
                        </div>
                        <div className="h5 text-warning text-center ">
                          {estacionesAirePM25 && estacionesAirePM25[5]!==undefined?estacionesAirePM25[5].categoria:''}
                        </div>
                        <div className="row justify-content-center">
                          <div className="cuadroText text-center">
                            <div className="bigText2">{estacionesAirePM25 && estacionesAirePM25[5]!==undefined?estacionesAirePM25[5].valorICA:''}</div>
                            <div>{estacionesAirePM25 && estacionesAirePM25[5]!==undefined?estacionesAirePM25[5].unidadesValorICA:''}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
}

export default App;
